import React, { Component } from "react";
import _ from "lodash";

export default class App extends Component {
    constructor() {
        super();
        this.state = {
            employee: {
                name: "张三",
                age: 20,
            },
        };
    }
    render() {
        console.log(_.isEqual(this.state.employee, { name: "张三", age: 20 }));

        const { name, age } = this.state.employee;
        return <div style={{ backgroundColor: "red", height: 100 }}></div>;
    }
}
